var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"content-section"},[_c('div',{staticClass:"progress-bar"},[_c('div',{staticClass:"progress",style:({ width: _vm.progressWidth + '%'})})]),_c('div',{staticClass:"form-section"},[_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[(_vm.step == 1)?_c('Accusation',{key:"1"}):_vm._e(),(_vm.step == 2)?_c('AccusationExtended',{key:"2"}):_vm._e(),(_vm.step == 3)?_c('Insurance',{key:"3"}):_vm._e(),(_vm.step == 4)?_c('PostFromOffice',{key:"4"}):_vm._e(),(_vm.step == 5)?_c('SpeedAccusationLocation',{key:"5"}):_vm._e(),(_vm.step == 6)?_c('SpeedAccusationSpeed',{key:"6"}):_vm._e(),(_vm.step == 7)?_c('DistanceAccusationSpeed',{key:"7"}):_vm._e(),(_vm.step == 8)?_c('DistanceAccusationDistance',{key:"8"}):_vm._e(),(_vm.step == 9)?_c('RedlightAccusationRisk',{key:"9"}):_vm._e(),(_vm.step == 10)?_c('RedlightAccusationDamage',{key:"10"}):_vm._e(),(_vm.step == 11)?_c('RedlightAccusationTime',{key:"11"}):_vm._e(),(_vm.step == 12)?_c('section',{key:"16"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendData($event)}}},[_c('div',{key:"personalData",staticClass:"section"},[_c('div',{staticClass:"section-title"},[_c('div',{staticClass:"author"},[_c('img',{attrs:{"src":require("@/assets/images/rabier.png")}})]),_c('h3',[_vm._v("Fast geschafft! Wohin dürfen wir Ihr Ergebnis senden?")]),_c('p',{staticClass:"green-color"},[_vm._v("Die Prüfung Ihres Falles ist für Sie "),_c('b',[_vm._v("kostenlos & unverbindlich")]),_vm._v(". Es erfolgt keine Weitergabe Ihrer Daten!")])]),_c('div',{staticClass:"form-container form-data"},[_c('div',{staticClass:"input-row two-input"},[_c('div',{staticClass:"wrap-input"},[_c('ValidationProvider',{attrs:{"mode":"eager","name":"vorname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.firstname),expression:"user.firstname"}],attrs:{"type":"text"},domProps:{"value":(_vm.user.firstname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "firstname", $event.target.value)}}}),_c('span',{staticClass:"label-input",class:{ filled : _vm.user.firstname.length >= 1 }},[_vm._v("Ihr Vorname *")]),_c('p',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"wrap-input"},[_c('ValidationProvider',{attrs:{"mode":"eager","name":"nachname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.name),expression:"user.name"}],attrs:{"type":"text"},domProps:{"value":(_vm.user.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "name", $event.target.value)}}}),_c('span',{staticClass:"label-input",class:{ filled : _vm.user.name.length >= 1 }},[_vm._v("Ihr Nachname *")]),_c('p',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"input-row"},[_c('div',{staticClass:"wrap-input"},[_c('ValidationProvider',{attrs:{"mode":"eager","name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],attrs:{"type":"email"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}}),_c('span',{staticClass:"label-input",class:{ filled : _vm.user.email.length >= 1 }},[_vm._v("Ihre E-Mail Adresse *")]),_c('p',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"input-row"},[_c('div',{staticClass:"wrap-input"},[_c('MazPhoneNumberInput',{attrs:{"type":"tel","default-country-code":"DE","no-country-selector":true,"only-countries":['DE'],"placeholder":"Ihre Telefonnummer*","translations":{
                                    countrySelectorLabel: 'Land',
                                    countrySelectorError: 'Bitte Land wählen',
                                    phoneNumberLabel: 'Ihre Telefonnummer',
                                    example: 'Beispiel:'
                                }},on:{"update":function($event){_vm.phonenumberResult = $event}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)])])]),_c('div',{key:"18",staticClass:"form-control"},[_c('transition',{attrs:{"name":"fade"}},[_c('button',{staticClass:"next submit",attrs:{"type":"submit","disabled":invalid == true || _vm.formSubmitted == true}},[(_vm.isLoading == true)?_c('span'):_vm._e(),_vm._v("Kostenlose Auswertung anfordern")])]),_c('p',[_c('a',{staticClass:"prev",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.prev()}}},[_c('svg',[_c('use',{attrs:{"xlink:href":require("@/assets/images/sprite.svg") + "#right-arrow"}})]),_vm._v("Zurück")])])],1)])]}}],null,false,4177365110)})],1):_vm._e(),(_vm.step == 13)?_c('div',{key:"17",staticClass:"section result-section"},[_c('div',{staticClass:"section-title"},[_c('div',{staticClass:"icon-document"},[_c('img',{attrs:{"src":require("@/assets/images/icons-check-document-active.png")}})]),_c('h3',[_vm._v("Anfrage erfolgreich eingegangen. "),_c('br'),_vm._v(" Ihre Fallnummer lautet "+_vm._s(_vm.user.casenumber))]),_c('p',[_vm._v("Vielen Dank für Ihre Anfrage! Ihr Fall ist nun in Bearbeitung und wir werden uns umgehend bei Ihnen melden.")])])]):_vm._e()],1),(_vm.step != 12)?_c('div',{key:"18",staticClass:"form-control"},[_c('transition',{attrs:{"name":"fade"}},[((_vm.checked >= 1) & _vm.step < 12)?_c('button',{staticClass:"next",on:{"click":function($event){return _vm.next()}}},[(_vm.isLoading == true)?_c('span'):_vm._e(),_vm._v("Weiter")]):_vm._e()]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.step > 1),expression:"step > 1"}]},[_c('a',{staticClass:"prev",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.prev()}}},[_c('svg',[_c('use',{attrs:{"xlink:href":require("@/assets/images/sprite.svg") + "#right-arrow"}})]),_vm._v("Zurück")])])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }